<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <defaultMessages />
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    defaultMessages () { return import('@/components/reminder/defaultMessages') }
  }
}
</script>
